import React from "react";
import Layout from "../components/layout";
import * as styles from "./index.module.css";
import * as styleMain from "../styles/main.module.css";
import bannerPhoto1 from "../images/bannerPhoto1.png";
import ourPeoplePhoto from "../images/ourPeoplePhoto.png";
import businessQualityPhoto from "../images/businessQualityPhoto.png";
import businessServicePhoto from "../images/businessServicePhoto.png";
import businessValuePhoto from "../images/businessValuePhoto.png";

const Index = () => {
  return (
    <Layout>
      <main className={styles.index}>
        {/* ====== 0 banner ====== */}
        <section className={styleMain.banner}>
          <h2>Help towards a business success</h2>
          <h5>
            We focus on your business, not the computers, providing
            cost-effective systems
            <br />
            that save time, effort and money by meeting real business needs.
          </h5>
          <button className={styleMain.bannerBtn}>FIND OUT MORE</button>
          <img
            className={styleMain.bannerImg}
            src={bannerPhoto1}
            alt="banner_photo_1"
          />
        </section>
        {/* ====== 1 aboutUs ====== */}
        <section className={styles.aboutUs}>
          <h3>About Us</h3>
          <p>
            We specialise in the design, development and implementation of
            innovative Learning Management, Student Management, Training
            Management and Business Systems for Schools and RTO in Australia. We
            offer expertise and a wide industry perspective on finding
            innovative and cost-effective software solutions for your business
            needs.
          </p>
          <p>
            We offer fully-supported customised solutions that implement all
            phases of the software development life-cycle, from scoping and
            requirements gathering to deployment, with ongoing support and user
            training. We focus on your business, not the computers, providing
            cost-effective systems that save time, effort and money by meeting
            real business needs.
          </p>
        </section>
        {/* ====== 2 ourPeople ====== */}
        <section className={styles.ourPeople}>
          <aside>
            <h3>Our People</h3>
            <p>
              We consist of a team of professionals with various skillset and
              knowledge which cover data analytics, Aland Machine Learning.
            </p>
            <p>
              We have successfully delivered data-driven solution and system for
              our clients from high schools, RTOs and universities, with view to
              improve their productivity and efficiency.
            </p>
            <p>
              We provide the solution, expertise, and support to assist the
              business partners to manage their daily operation which cover
              transaction, CMS, marketing, and finance sectors.
            </p>
            <p>
              Our IT development team consist of project managers, data
              scientists, UI/UX designers, programmers, and testing engineers.
            </p>
          </aside>
          <img src={ourPeoplePhoto} alt="our_people_photo" />
        </section>
        {/* ====== 3 businessPhilo ====== */}
        <section className={styles.businessPhilo}>
          <h3>Our Business Philosophy</h3>
          <div className={styles.flex}>
            <article>
              <img src={businessQualityPhoto} alt="business_quality_photo" />
              <h4>Quality</h4>
              <p>
                Our commitment is to develop software systems with fine quality.
                With over 20 years' experience in IT industry, we are keen to
                offering our clients with a reliable and stable solution and
                systems as simple as possible.
              </p>
            </article>
            <article>
              <img src={businessServicePhoto} alt="business_service_photo" />
              <h4>Service</h4>
              <p>
                All development and support service are provided by our local
                team. We can response rapidly and efficiently to your request
                without any delay caused by different time zone or overseas
                support. Our technical experts will listen to your voice and
                provide best cost-benefit solution to fulfill your business
                needs.
              </p>
            </article>
            <article>
              <img src={businessValuePhoto} alt="business_value_photo" />
              <h4>Value</h4>
              <p>
                Our aim is simple but focus on providing cost-effective
                solutions aligned with our client's needs. We are committed to
                helping our client's make better decision and improve their
                performance. This empathy makes us better understand their
                business needs and contribute to their continued success through
                our development of appropriate solution and software systems.
              </p>
            </article>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default Index;

export const Head = () => <title>Auokka | Home</title>;
